import axios from "axios";

export async function getInfoUhoo(token) {
  try {
    let response = await axios.get(
      "https://api.brilliant360.net/api/post/page/0?page_size=10",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    return response;
  } catch (error) {
    return "";
  }
}
