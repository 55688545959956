import axios from "axios";

const KEY = "AIzaSyChK1mtd8RbccZ6Be5ZodC7HLs7dVe55oc";
//const id = "dhvKSo27Wfc"

export async function getDataYoutube(videoId) {
  try {
    let response = await axios.get(
      "https://www.googleapis.com/youtube/v3/videos?",
      {
        params: {
          part: "snippet",
          key: KEY,
          id: videoId,
        },
        headers: {},
      });
      return response;
  } catch (error) {
    return "";
  }
}
